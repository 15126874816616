import { jsx as a } from "react/jsx-runtime";
import { styled as u } from "@linaria/react";
import { useLayoutContext as m, LayoutProvider as n } from "./Layout.context.js";
import i from "clsx";
/* empty css                     */
const l = /* @__PURE__ */ u("div")({
  name: "SLayout",
  class: "SLayout_s1cs40t4",
  propsAsIs: !1
}), y = ({
  children: t,
  hideNav: o = !1,
  className: s,
  ...r
}) => {
  const {
    layoutRef: e
  } = m();
  return /* @__PURE__ */ a(l, {
    className: i(s, {
      "without-nav": o
    }),
    ...r,
    ref: e,
    children: t
  });
}, x = ({
  children: t,
  mobileLayout: o = "default",
  ...s
}) => /* @__PURE__ */ a(n, {
  mobileLayout: o,
  children: /* @__PURE__ */ a(y, {
    ...s,
    "data-test-id": "layout",
    children: t
  })
});
export {
  x as Layout,
  y as LayoutInner,
  l as SLayout
};
