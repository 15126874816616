import { jsx as s } from "react/jsx-runtime";
import { styled as r } from "@linaria/react";
import { useLayoutContext as e } from "./Layout.context.js";
/* empty css                        */
const u = /* @__PURE__ */ r("nav")({
  name: "SLayoutNav",
  class: "SLayoutNav_soa799p",
  propsAsIs: !1
}), v = ({
  children: t,
  ...a
}) => {
  const {
    layoutNavRef: o
  } = e();
  return /* @__PURE__ */ s(u, {
    ...a,
    ref: o,
    "data-test-id": "layout-navbar",
    children: t
  });
};
export {
  v as LayoutNav,
  u as SLayoutNav
};
